import React from "react"
import About from "../components/about"
import Header2 from "../components/header2"
import Mainfooter from "../components/mainfooter"
import Seo from "../components/seo"

const aboutus = () => {
  return (
    <>
      <Seo title="About Us" />
      <Header2 />
      <div className="aboutstyle">
        <About />
      </div>
      <Mainfooter />
    </>
  )
}

export default aboutus
