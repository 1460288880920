import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./about.css"

const About = () => {
  return (
    <div className="about-sec">
      <div
        className="container  d-flex align-items-center"
        style={{ minHeight: "50vh" }}
      >
        <div className="row col-reverse">
          <div className="col-md-6">
            <StaticImage
              src="../images/img3.jpg"
              alt="People talking"
              placeholder="blurred"
              width={600}
              height={350}
            />
          </div>
          <div className="col-md-6">
            <div className="abt-content">
              <div className="abt-heading">
                <h1 className="display-1">About us</h1>
              </div>
              <div className="abt-para h">
                <p style={{ fontsize: "15px" }}>
                  Making important purchases for your business is a tough task.
                  You could call individual companies and fight your way through
                  a maze of recordings, but that takes time and time is money.
                  Superior Merchant helps to get away from all the husstle. You
                  find everything at one spot. We provide you hand-picked leads
                  that help you grow your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default About
